import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "test",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#test",
        "aria-label": "test permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Test`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "python"
    }}><pre parentName="div" {...{
        "className": "language-python"
      }}><code parentName="pre" {...{
          "className": "language-python"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`my_function`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`stuff`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` stuff`}</code></pre></div>
    <div {...{
      "className": "math math-display"
    }}><span parentName="div" {...{
        "className": "katex-display"
      }}><span parentName="span" {...{
          "className": "katex"
        }}><span parentName="span" {...{
            "className": "katex-mathml"
          }}><math parentName="span" {...{
              "xmlns": "http://www.w3.org/1998/Math/MathML",
              "display": "block"
            }}><semantics parentName="math"><mrow parentName="semantics"><munderover parentName="mrow"><mo parentName="munderover">{`∑`}</mo><mrow parentName="munderover"><mi parentName="mrow">{`i`}</mi><mo parentName="mrow">{`=`}</mo><mn parentName="mrow">{`1`}</mn></mrow><mrow parentName="munderover"><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∣`}</mi><mi parentName="mrow">{`V`}</mi><mi parentName="mrow" {...{
                        "mathvariant": "normal"
                      }}>{`∣`}</mi></mrow></munderover><msub parentName="mrow"><mi parentName="msub">{`x`}</mi><mi parentName="msub">{`i`}</mi></msub><msub parentName="mrow"><mi parentName="msub">{`x`}</mi><mi parentName="msub">{`g`}</mi></msub></mrow><annotation parentName="semantics" {...{
                  "encoding": "application/x-tex"
                }}>{`\\sum_{i=1}^{\\vert V \\vert}x_{i} x_{g}`}</annotation></semantics></math></span><span parentName="span" {...{
            "className": "katex-html",
            "aria-hidden": "true"
          }}><span parentName="span" {...{
              "className": "base"
            }}><span parentName="span" {...{
                "className": "strut",
                "style": {
                  "height": "3.2387em",
                  "verticalAlign": "-1.2777em"
                }
              }}></span><span parentName="span" {...{
                "className": "mop op-limits"
              }}><span parentName="span" {...{
                  "className": "vlist-t vlist-t2"
                }}><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "1.961em"
                      }
                    }}><span parentName="span" {...{
                        "style": {
                          "top": "-1.8723em",
                          "marginLeft": "0em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3.05em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "sizing reset-size6 size3 mtight"
                        }}><span parentName="span" {...{
                            "className": "mord mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mathnormal mtight"
                            }}>{`i`}</span><span parentName="span" {...{
                              "className": "mrel mtight"
                            }}>{`=`}</span><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`1`}</span></span></span></span><span parentName="span" {...{
                        "style": {
                          "top": "-3.05em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3.05em"
                          }
                        }}></span><span parentName="span"><span parentName="span" {...{
                            "className": "mop op-symbol large-op"
                          }}>{`∑`}</span></span></span><span parentName="span" {...{
                        "style": {
                          "top": "-4.386em",
                          "marginLeft": "0em"
                        }
                      }}><span parentName="span" {...{
                          "className": "pstrut",
                          "style": {
                            "height": "3.05em"
                          }
                        }}></span><span parentName="span" {...{
                          "className": "sizing reset-size6 size3 mtight"
                        }}><span parentName="span" {...{
                            "className": "mord mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`∣`}</span><span parentName="span" {...{
                              "className": "mord mathnormal mtight",
                              "style": {
                                "marginRight": "0.22222em"
                              }
                            }}>{`V`}</span><span parentName="span" {...{
                              "className": "mord mtight"
                            }}>{`∣`}</span></span></span></span></span><span parentName="span" {...{
                      "className": "vlist-s"
                    }}>{`​`}</span></span><span parentName="span" {...{
                    "className": "vlist-r"
                  }}><span parentName="span" {...{
                      "className": "vlist",
                      "style": {
                        "height": "1.2777em"
                      }
                    }}><span parentName="span"></span></span></span></span></span><span parentName="span" {...{
                "className": "mspace",
                "style": {
                  "marginRight": "0.1667em"
                }
              }}></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.3117em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight"
                              }}>{`i`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.15em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span><span parentName="span" {...{
                "className": "mord"
              }}><span parentName="span" {...{
                  "className": "mord mathnormal"
                }}>{`x`}</span><span parentName="span" {...{
                  "className": "msupsub"
                }}><span parentName="span" {...{
                    "className": "vlist-t vlist-t2"
                  }}><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.1514em"
                        }
                      }}><span parentName="span" {...{
                          "style": {
                            "top": "-2.55em",
                            "marginLeft": "0em",
                            "marginRight": "0.05em"
                          }
                        }}><span parentName="span" {...{
                            "className": "pstrut",
                            "style": {
                              "height": "2.7em"
                            }
                          }}></span><span parentName="span" {...{
                            "className": "sizing reset-size6 size3 mtight"
                          }}><span parentName="span" {...{
                              "className": "mord mtight"
                            }}><span parentName="span" {...{
                                "className": "mord mathnormal mtight",
                                "style": {
                                  "marginRight": "0.03588em"
                                }
                              }}>{`g`}</span></span></span></span></span><span parentName="span" {...{
                        "className": "vlist-s"
                      }}>{`​`}</span></span><span parentName="span" {...{
                      "className": "vlist-r"
                    }}><span parentName="span" {...{
                        "className": "vlist",
                        "style": {
                          "height": "0.2861em"
                        }
                      }}><span parentName="span"></span></span></span></span></span></span></span></span></span></span></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      